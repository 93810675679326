@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT';
  src: url('assets/fonts/SUIT-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}