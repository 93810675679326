body {
  margin: 0;
  font-family: 'SUIT', 'Pretendard', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101010;

}

:root {
  --vh: 100%;
}

/* 모든 요소에 box-sizing: border-box 적용 */
*, *::before, *::after {
  box-sizing: border-box;
}